<template>
  <div>
    <v-tab-item :key="task.id">
      <v-card color="basil" flat>
        <v-card-text>
          <v-treeview
            v-model="selectedCheckbox"
            :selectable="canEdit"
            activatable
            selected-color="primary"
            :items="task.dIs"
            return-object
          >
            <template v-if="!canEdit" v-slot:prepend="{ item }">
              <v-checkbox
                style="margin: -5px -8px 0px 0px;"
                :input-value="item.s === 1"
                disabled
                dense
                hide-details
              ></v-checkbox>
            </template>
            <template v-slot:append="{ item, leaf }">
              <v-tooltip v-if="!leaf && canEdit" top>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      small
                      v-on="on"
                      icon
                      :color="hover ? 'red darken-4' : 'red'"
                      @click.stop="confirmDelete(item)"
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <span>Delete task</span>
              </v-tooltip>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-dialog v-if="confirmDeleteModal" v-model="confirmDeleteModal" max-width="500px">
      <v-card>
        <v-card-title style="background-color: #4AA6D5" class="headline">Delete Task</v-card-title>
        <v-card-text class='text-center'>
          Are you sure you want to delete '{{selectedIssue.name}}' ?
        </v-card-text>
        <v-card-actions class="pb-6 pt-4">
          <v-col cols="12" class="text-center ma-0 pa-0">
            <v-btn
              color="primary"
              class="mr-3"
              :loading="loading"
              :disabled="loading"
              @click="deleteIssue(selectedIssue)"
            >
              Delete
            </v-btn>
            <v-btn
              color="red"
              :loading="loading"
              :disabled="loading"
              @click="confirmDeleteModal = false"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IssueTask from '@/services/controllers/IssueTask'

export default {
  name: 'IssueModelTabItem',
  props: {
    formatWorkflow: {
      type: Array,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    currentBurst: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    selectedCheckbox: [],
    hasMounted: false,
    confirmDeleteModal: false,
    selectedIssue: {},
    loading: false,
  }),
  computed: {
    canEdit() {
      return this.$store.state.Permissions.campaignMilestoneEdit
    }
  },
  watch: {
    selectedCheckbox: {
      handler: function (newVal, oldVal) {
        if (this.hasMounted && newVal.length !== oldVal.length && this.canEdit) {
          const addedValues = new Set(newVal.filter(val => oldVal.findIndex(oldValVal => oldValVal.diId === val.diId && oldValVal.cbsId === val.cbsId) === -1))
          const removedValues = new Set(oldVal.filter(val => newVal.findIndex(newValVal => newValVal.diId === val.diId && newValVal.cbsId === val.cbsId) === -1))

          addedValues.forEach(async (val) => {
            await IssueTask.setDTaskState(val.diId, val.cbsId, true)
              .then((res) => {
                this.$root.$emit('updateOrDeleteCampaign', 'update', res.data)
                this.$root.$emit('snackbarSuccess', 'Task "' + val.name + '" completed!')
              })
              .catch((err) => {
                this.$root.$emit('snackbarError', err.response.data.message)
              })
          })

          removedValues.forEach(async (val) => {
            await IssueTask.setDTaskState(val.diId, val.cbsId, false)
              .then((res) => {
                this.$root.$emit('updateOrDeleteCampaign', 'update', res.data)
                this.$root.$emit('snackbarSuccess', 'Task "' + val.name + '" undone!')
              })
              .catch((err) => {
                this.$root.$emit('snackbarError', err.response.data.message)
              })
          })
        }
        this.hasMounted = true
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    if (this.canEdit) {
      let selected = []
      this.task.dIs.forEach((issue) => {
        if(issue.children){
          issue.children.forEach((child) => {
            if(child.s === 1) {
              selected.push(child)
            }
          })
        }
      })
      this.selectedCheckbox = selected
    }
  },
  methods: {
    async deleteIssue(issue) {
      if (!this.canEdit) return;
      
      this.loading = true;
      await IssueTask.deleteCustomIssue(this.currentBurst.id, issue.id)
        .then((res) => {
          this.$root.$emit('updateBurstAfterDeleteIssue', this.currentBurst.id, res.data)
          this.$root.$emit('snackbarSuccess', 'Issue ' + issue.name + ' deleted successfully')
          this.confirmDeleteModal = false
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', err.response.data.message)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    confirmDelete(issue) {
      if (!this.canEdit) return;
      
      this.confirmDeleteModal = true
      this.selectedIssue = issue
    }
  }
}
</script>